import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import { useAuth } from 'context';
import axios from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const { authTokens } = useAuth();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + authTokens
  };

  const [loading, setLoading] = useState(true);

  const existingProfile = JSON.parse(localStorage.getItem('user_data'));
  const [user, setUserData] = useState(existingProfile);

  const userprofile = async () => {
    if (!existingProfile) {
      const result = await axios(process.env.REACT_APP_MYPROFILE_ENDPOINT, {
        headers: headers
      });
      if (result.status === 200) {
        localStorage.setItem('user_data', JSON.stringify(result.data));
        setUserData(result.data);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    userprofile();
  }, []);
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {loading ? (
        <Skeleton variant="circle" width={40} height={40} />
      ) : (
        <Avatar
          alt="System"
          className={classes.avatar}
          component={RouterLink}
          src={process.env.REACT_APP_IMAGES + user.avatar}
        />
      )}
      <Typography className={classes.name} variant="h4">
        {loading ? <Skeleton /> : user.firstname + ' ' + user.lastname}
      </Typography>
      <Typography variant="body2">
        {loading ? <Skeleton /> : user.role}
      </Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
