import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { Offline, Online, Detector } from 'react-detect-offline';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      textAlign: 'center'
    }
  },

}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  
  const [open, SetOpen] = useState(false);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Detector
        render={({ online }) => (
          <div>
            {online ? SetOpen(false) : SetOpen(true)}
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              open={open}
              message="You are currently offline. Check your internet connection "
            />
          </div>
        )}
      />

      <Typography variant="body1">
        &copy;{' '}
        <Link component="a" href="https://booksdelivery.com" target="_blank">
          BooksDelivery
        </Link>
        . 2020. All rights reserved.
      </Typography>
      <Typography variant="caption">
        Created with <FavoriteIcon color="primary" fontSize="small" /> for
        Jagansindia!
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
