import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { useAuth } from 'context';
import { ProductList, Loading } from '../ProductList';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0)
    }
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));


const History = () => {
  const classes = useStyles();

  const { authTokens } = useAuth();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + authTokens
  };
  const [MyLoading, SetMyLoading] = useState(true);
  const [AllPicksData, setAllPicks] = useState(false);

  useEffect(() => {
    const fetchAllPicks = async () => {
      const result = await axios(process.env.REACT_APP_HISTORY_ENDPOINT, {
        headers: headers
      });
      if (result.status === 200) {
        setAllPicks(result.data);
        SetMyLoading(false);
      }
    };

    fetchAllPicks();
  }, []);

  let orders = AllPicksData.picklist;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h4" style={{ paddingLeft: '10px' }}>
          History Items
        </Typography>
        {MyLoading ? <Loading /> : <ProductList data={orders} />}
      </div>
    </div>
  );
};

export default History;
