import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from 'context';
import jwt from 'jsonwebtoken';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;
  const { authTokens } = useAuth();
  var isValid = false;

  jwt.verify(authTokens, process.env.REACT_APP_JWT_PUBLIC_KEY, function(err) {
    if (err) {
      isValid = false;
    } else {
      isValid = true;
    }
  });

  return (
    <Route
      {...rest}
      render={matchProps =>
        isValid ? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Layout>
            <Redirect to="/sign-in" />
          </Layout>
        )
      }
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
