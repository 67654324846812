import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField, Typography, Link } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import { useAuth } from 'context';
import axios from 'axios';
import { Topbar } from 'layouts/Minimal/components';
import FavoriteIcon from '@material-ui/icons/Favorite';

const schema = {
  username: {
    presence: { allowEmpty: false, message: 'is required' },

    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  logo: {
    display: 'flex',
    justifyContent: 'center'
  },
  form: {
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    justifyContent: 'center',
    display: 'flex'
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  footer: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      textAlign: 'center'
    }
  }
}));

const SignIn = props => {
  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const { setAuthTokens } = useAuth();

  useEffect(() => {
    setAuthTokens(); //reset tokens in context provider to prevent users back button after they logut.
    localStorage.clear(); // to clear off old token and user profiles
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = async event => {
    event.preventDefault();

    let data = {
      username: formState.values.username,
      password: formState.values.password
    };

    try {
      let response = await axios.post(
        process.env.REACT_APP_LOGIN_ENDPOINT,
        data
      );

      if (
        response.status === 201 &&
        response.data.access_token &&
        response.data.expires_in
      ) {
        setAuthTokens(response.data.access_token);
        setLoggedIn(true);
        setIsInvalid(false);
        history.push('/'); 
      } else {
        setIsError(true);
      }
    } catch (e) {
      setIsError(true);
      if (e.response.status === 401 || isError === true || isLoggedIn === false) {
        setIsInvalid(e.response.data.message);
      } else {
        setIsInvalid('Something went wrong!');
      }
    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Topbar></Topbar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.content}>
          <div className={classes.contentBody}>
            <form className={classes.form} onSubmit={handleSignIn}>
              <div className={classes.logo}>
                <img alt="Logo" src="./images/seller.png" />
              </div>
              <Typography className={classes.title} variant="h2">
                BooksDelivery Seller
              </Typography>

              <TextField
                className={classes.textField}
                error={hasError('username')}
                fullWidth
                helperText={
                  hasError('username') ? formState.errors.username[0] : null
                }
                label="Username"
                name="username"
                onChange={handleChange}
                type="text"
                value={formState.values.username || ''}
                variant="outlined"
              />
              <TextField
                className={classes.textField}
                error={hasError('password')}
                fullWidth
                helperText={
                  hasError('password') ? formState.errors.password[0] : null
                }
                label="Password"
                name="password"
                onChange={handleChange}
                type="password"
                value={formState.values.password || ''}
                variant="outlined"
              />
              <Button
                className={classes.signInButton}
                color="primary"
                disabled={!formState.isValid}
                fullWidth
                size="large"
                type="submit"
                variant="contained">
                Sign in now
              </Button>
              {isInvalid !== false ? (
                <Alert severity="error"> {isInvalid} </Alert>
              ) : null}
              {/* <Typography color="textSecondary" variant="body1">
                Don't have an account?{' '}
                <Link component={RouterLink} to="/sign-up" variant="h6">
                  Sign up
                </Link>
              </Typography> */}
            </form>
          </div>
          <div className={classes.footer}>
            <Typography variant="body1">
              &copy;{' '}
              <Link
                component="a"
                href="https://booksdelivery.com"
                target="_blank">
                BooksDelivery
              </Link>
              . 2020. All rights reserved.
            </Typography>
            <Typography variant="caption">
              Created with <FavoriteIcon color="primary" fontSize="small" /> for
              Jagansindia!
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
