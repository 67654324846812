import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0)
    }
  },
  content: {
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
    //  margin: 'auto',
    //  maxWidth: 500
  },
  textbox: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    padding: theme.spacing(0.75),
    fontWeight: 'bold'
  },
  orangebox: {
    backgroundColor: '#ff9800',
    color: theme.palette.white,
    padding: theme.spacing(0.75),
    fontWeight: 'bold'
  },
  greenbox: {
    backgroundColor: '#8bc34a',
    color: theme.palette.white,
    padding: theme.spacing(0.75),
    fontWeight: 'bold'
  },
  listItemTextSecondary: {
    paddingTop: theme.spacing(0.75)
  }
}));

export function Loading() {
  const classes = useStyles();
  const variants = ['h1', 'h3', 'body1', 'caption'];
  return (
    <div>
      {variants.map(variant => (
        <Paper className={classes.paper} key={variant}>
          <Grid container spacing={2} key={variant}>
            <Grid item container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="h1">
                    <Skeleton width="50%" />
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    <Skeleton />
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    <Skeleton />
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <Skeleton />
                  </Typography>
                </Grid>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </div>
  );
}

export const ProductList = (props) => {


  const classes = useStyles();

  let orders = props.data;

  return (
    <Paper className={classes.paper}>
      <List>
        {orders.map(order => (
          <ListItem alignItems="flex-start" key={order.order_product_id}>
            <ListItemText
              primary={order.product_name}
              secondary={
                <React.Fragment>
                  <Typography variant="subtitle2" component={'span'}>
                    {order.author + ' - ' + order.manufacturer_name}
                  </Typography>
                  <br />
                  <Typography
                    component={'span'}
                    variant="body2"
                    color="textSecondary">
                    ID:{' '}
                    {order.order_id +
                      '_' +
                      order.order_product_id +
                      ' - ' +
                      moment(order.created_time).format('lll')}{' '}
                  </Typography>
                  <br />
                  <Typography
                    component={'span'}
                    variant="body2"
                    color="textSecondary"
                    gutterBottom>
                    <CheckCircleOutlineIcon
                      style={{ fontSize: 15 }}
                      color="primary"
                    />
                    {' ' + moment(order.picked_time).format('lll') + ' '}
                    <CheckCircleIcon
                      style={{ fontSize: 15 }}
                      color="primary"
                    />{' '}
                    {moment(order.verified_time).format('lll')}
                  </Typography>
                  <br />
                  <div className={classes.listItemTextSecondary}>
                    <span className={classes.textbox}>
                      MRP : &#8377; {order.mrp}
                    </span>
                    <span component="span"> &nbsp; </span>
                    <span component="span" className={classes.orangebox}>
                      Qty : {order.pick_quantity}
                    </span>
                    <span component="span"> &nbsp; </span>
                    <span component="span" className={classes.greenbox}>
                      {order.pick_discount} %
                    </span>
                    <span component="span"> &nbsp; </span>
                    <span component="span" className={classes.textbox}>
                      &#8377; {order.pick_total}
                    </span>
                  </div>
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default ProductList;