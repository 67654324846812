import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { MyPicks } from '../../components';
import axios from 'axios';
import { Grid, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useAuth } from 'context';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  }
}));

function Loading() {
  const variants = ['h1', 'h3', 'body1', 'caption'];
return (
  <div>
    {variants.map(variant => (
      <Grid container spacing={2} key={variant}>
        <Grid item xs={3} sm={2} lg={1} container>
          <Skeleton variant="rect" width="100%">
            <div style={{ paddingTop: '100' }} />
          </Skeleton>
        </Grid>
        <Grid item xs={9} sm={10} lg={11} container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="h1">
                <Skeleton />
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                <Skeleton />
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                <Skeleton />
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <Skeleton />
              </Typography>
            </Grid>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
    ))}
  </div>
);
}

 
export default function OrdersTab() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    SetMyLoading(true);
    SetAllLoading(true);
    fetchAllPicks();
    fetchMyPicks();
  };

  const handleChangeIndex = index => {
    setValue(index);
     SetMyLoading(true);
     SetAllLoading(true);
     fetchAllPicks();
     fetchMyPicks();
  };

 const fetchAllPicks = async () => {
   const result = await axios(process.env.REACT_APP_MYPICKS_ENDPOINT, {
     headers: headers
   });
   if (result.status === 200) {
     setAllPicks(result.data);
     SetMyLoading(false);
   }
 };

 const fetchMyPicks = async () => {
   const result = await axios(process.env.REACT_APP_ALLPICKS_ENDPOINT, {
     headers: headers
   });
   if (result.status === 200) {
     setMyPicks(result.data);
     SetAllLoading(false);
   }
 };

 const { authTokens } = useAuth();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + authTokens
  };
  const [MyLoading, SetMyLoading] = useState(true);
  const [AllLoading, SetAllLoading] = useState(true);
  const [AllPicksData, setAllPicks] = useState(false);
  const [MyPicksData, setMyPicks] = useState(false);

  useEffect(() => {
    const fetchAllPicks = async () => {
      const result = await axios(process.env.REACT_APP_MYPICKS_ENDPOINT, {
        headers: headers
      });
      if (result.status === 200) {
        setAllPicks(result.data);
        SetMyLoading(false);
      } 
    };

    const fetchMyPicks = async () => {
      const result = await axios(process.env.REACT_APP_ALLPICKS_ENDPOINT, {
        headers: headers
      });
      if (result.status === 200) {
        setMyPicks(result.data);
        SetAllLoading(false);
      }
    };
    fetchAllPicks();
    fetchMyPicks();
  }, []);

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth">
        <Tab label="My Picks" />
        <Tab label="Outstanding Picks" />
      </Tabs>

      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}>
        <div value={value} index={0} dir={theme.direction}>
          {MyLoading ? (
            <Loading />
          ) : (
            <MyPicks tabs={value} data={AllPicksData} />
          )}
        </div>
        <div value={value} index={1} dir={theme.direction}>
          {AllLoading ? (
            <Loading />
          ) : (
            <MyPicks tabs={value} data={MyPicksData} />
          )}
        </div>
      </SwipeableViews>
    </div>
  );
}
