import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { useAuth } from 'context';
import { ProductList, Loading } from '../ProductList';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0)
    }
  },
  content: {
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
    //  margin: 'auto',
    //  maxWidth: 500
  },
  image: {
    width: 128,
    height: 128
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  rounded: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(1)
  },
  textbox: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    padding: theme.spacing(0.75),
    fontWeight: 'bold'
  },
  orangebox: {
    backgroundColor: '#ff9800',
    color: theme.palette.white,
    padding: theme.spacing(0.75),
    fontWeight: 'bold'
  },
  greenbox: {
    backgroundColor: '#8bc34a',
    color: theme.palette.white,
    padding: theme.spacing(0.75),
    fontWeight: 'bold'
  }
}));



const Unsettled = () => {
  const classes = useStyles();

  const { authTokens } = useAuth();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + authTokens
  };
  const [MyLoading, SetMyLoading] = useState(true);
  const [AllPicksData, setAllPicks] = useState(false);

  useEffect(() => {
    const fetchAllPicks = async () => {
      const result = await axios(process.env.REACT_APP_UNSETTLED_ENDPOINT, {
        headers: headers
      });
      if (result.status === 200) {
        setAllPicks(result.data);
        SetMyLoading(false);
      }
    };

    fetchAllPicks();
  }, []);

  let orders = AllPicksData.picklist;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h4" style={{ paddingLeft: '10px' }}>
          Unsettled Settlement Items
        </Typography>
        {MyLoading ? <Loading /> : <ProductList data={orders} />}
      </div>
    </div>
  );
};

export default Unsettled;
