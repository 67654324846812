import React, { useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import axios from 'axios';
import { useAuth } from 'context';
import { Loading } from '../ProductList';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0)
    }
  },
  content: {
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
    //  margin: 'auto',
    //  maxWidth: 500
  },
  image: {
    width: 128,
    height: 128
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  rounded: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(1)
  },
  textbox: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    padding: theme.spacing(0.75),
    fontWeight: 'bold'
  },
  orangebox: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
    padding: theme.spacing(0.75),
    fontWeight: 'bold'
  },
  greenbox: {
    backgroundColor: '#8bc34a',
    color: theme.palette.white,
    padding: theme.spacing(0.75),
    fontWeight: 'bold'
  },
  
}));


const Transactions = () => {
  const classes = useStyles();

  const { authTokens } = useAuth();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + authTokens
  };
  const [MyLoading, SetMyLoading] = useState(true);
  const [AllPicksData, setAllPicks] = useState(false);

  useEffect(() => {
    const fetchAllPicks = async () => {
      const result = await axios(process.env.REACT_APP_TRANSACTIONS_ENDPOINT, {
        headers: headers
      });
      if (result.status === 200) {
        setAllPicks(result.data);
        SetMyLoading(false);
      }
    };

    fetchAllPicks();
  }, []);


  let transactions = AllPicksData.transactions;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h4" style={{ paddingLeft: '10px' }}>
          Payments Settlements
        </Typography>
        {MyLoading ? (
          <Loading />
        ) : (
          <Paper className={classes.paper}>
            <List>
              {transactions.map(transaction => (
                <ListItem alignItems="flex-start" key={transaction.ID}>
                  <ListItemText
                    classes={{
                      primary: classes.listItemText,
                      secondary: classes.listItemTextSecondary
                    }}
                    primary={'Payment ID: ' + transaction.ID}
                    secondary={
                      <React.Fragment>
                        <Typography variant="subtitle2">
                          Reference: {transaction.paid_reference}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          Transaction Time:{' '}
                          {moment(transaction.timestamp).format('lll')}
                        </Typography>
                        <span component="span" className={classes.textbox}>
                          Date:
                          {moment(transaction.date).format('ll')}
                        </span>
                        <span component="span"> &nbsp; </span>
                        <span component="span" className={classes.orangebox}>
                          Amount: &#8377;
                          {transaction.amount}
                        </span>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </div>
    </div>
  );
};

export default Transactions;