import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Orders as OrdersView,
  Unsettled as UnsettledView,
  History as HistoryView,
  Upcoming as UpcomingView,
  Transactions as TransactionsView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/orders" />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={UnsettledView}
        exact
        layout={MainLayout}
        path="/unsettled"
      />
      <RouteWithLayout
        component={HistoryView}
        exact
        layout={MainLayout}
        path="/history"
      />
      <RouteWithLayout
        component={UpcomingView}
        exact
        layout={MainLayout}
        path="/upcoming"
      />
      <RouteWithLayout
        component={TransactionsView}
        exact
        layout={MainLayout}
        path="/settlements"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={OrdersView}
        exact
        layout={MainLayout}
        path="/orders"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <Route
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
