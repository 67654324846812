import React, { useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import axios from 'axios';
import { useAuth } from 'context';

import {
  Budget,
  TotalUsers,
  TasksProgress,
  TotalProfit,
  LatestSales,
  UsersByDevice,
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const classes = useStyles();

   const { authTokens } = useAuth();

   const headers = {
     'Content-Type': 'application/json',
     Authorization: 'Bearer ' + authTokens
   };
   
   const [DashboardData, setDashboardData] = useState(false);

   useEffect(() => {
     const fetchAllPicks = async () => {
       const result = await axios(process.env.REACT_APP_DASHBOARD_ENDPOINT, {
         headers: headers
       });
       if (result.status === 200) {
         setDashboardData(result.data);
         
       }
     };

     fetchAllPicks();
   }, []);

   console.log(DashboardData);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <Budget data={DashboardData.unsettled_payments ? DashboardData.unsettled_payments : 0} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers data={DashboardData.total_orders ? DashboardData.total_orders : 0} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TasksProgress data={DashboardData.upcoming_payments ? DashboardData.upcoming_payments : 0} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalProfit data={DashboardData.total_earnings ? DashboardData.total_earnings : 0} />
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <LatestSales />
        </Grid>
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <UsersByDevice />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
