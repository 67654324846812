import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { OrdersTab } from './components';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0)
    }
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Orders = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <OrdersTab />
      <div className={classes.content}></div>
    </div>
  );
};

export default Orders;
