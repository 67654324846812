import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Avatar, IconButton } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import moment from 'moment';
import axios from 'axios';
import { useAuth } from 'context';
import Box   from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2)
    //  margin: 'auto',
    //  maxWidth: 500[90[]]
  },
  image: {
    width: 128,
    height: 128
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  rounded: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(1)
  },
  removebutton: {
    color: theme.palette.white,
    backgroundColor: red[500],
    marginTop: theme.spacing(1)
  },
  orderidtext: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    padding: theme.spacing(0.75),
    fontWeight: "bold"
  }
}));

export default function MyPicks(props) {
  const user = props.tabs;

  const classes = useStyles();

  //let orders = props.data.picklist;
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);
  const handleImageClickOpen = (value) => {
    setOpen(true);
    setSelectedImage(value);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(false);
  };

  const [orders, setPicks] = useState(props.data.picklist);
  

  const removePicks = index => {
    const newPicks = [...orders];
    newPicks.splice(index, 1);
    setPicks(newPicks);
  };
  
  const { authTokens } = useAuth();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + authTokens
  };
  

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        { selectedImage ? <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <img
            className={classes.img}
            alt=""
            src={process.env.REACT_APP_IMAGES + selectedImage}
          />
        </Dialog> : null }
        {orders ? (
          orders.map((order, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={3} sm={2} lg={1} container>
                <ButtonBase className={classes.image}>
                  <img
                    className={classes.img}
                    onClick={() => handleImageClickOpen(order.image)}
                    alt=""
                    src={process.env.REACT_APP_IMAGES + order.image}
                  />
                </ButtonBase>
              </Grid>

              <Grid item xs={9} sm={10} lg={11} container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography gutterBottom variant="subtitle1">
                      {order.product_name}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {order.author}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {order.manufacturer_name}
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                      <Box component="span">
                        ID:{' '}
                        {order.order_id + '_' + order.order_product_id + ' '}
                      </Box>
                      <Tooltip
                        title={moment(order.created_time).format('lll')}
                        placement="top">
                        <Box
                          component="span"
                          className={classes.orderidtext}
                          style={{
                            backgroundColor:
                              moment().diff(
                                moment(order.created_time),
                                'days'
                              ) >= 2 &&
                              moment().diff(
                                moment(order.created_time),
                                'days'
                              ) < 4
                                ? '#ff9800'
                                : moment().diff(
                                    moment(order.created_time),
                                    'days'
                                  ) <= 1
                                ? '#8bc34a'
                                : '#f44336'
                          }}>
                          {moment(order.created_time).fromNow()}
                        </Box>
                      </Tooltip>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    &#8377; {order.price}
                  </Typography>
                  <Avatar variant="rounded" className={classes.rounded}>
                    <IconButton
                      style={{ color: 'white' }}
                      onClick={async () => {
                        try {
                          let response = await axios.put(
                            user === 0
                              ? process.env.REACT_APP_MYPICKS_ENDPOINT +
                                  '/' +
                                  order.order_id +
                                  '/' +
                                  order.order_product_id
                              : process.env.REACT_APP_ALLPICKS_ENDPOINT +
                                  '/' +
                                  order.order_id +
                                  '/' +
                                  order.order_product_id,
                            { data: 'update' },
                            { headers: headers }
                          );
                          removePicks(index);
                          console.log(response);
                        } catch (e) {
                          console.log('error');
                        }
                      }}>
                      {order.pick_quantity}
                    </IconButton>
                  </Avatar>
                  {user === 0 ? (
                    <Avatar variant="rounded" className={classes.removebutton}>
                      <IconButton
                        style={{ color: 'white' }}
                        onClick={async () => {
                          try {
                            let response = await axios.delete(
                              process.env.REACT_APP_MYPICKS_ENDPOINT +
                                '/' +
                                order.order_id +
                                '/' +
                                order.order_product_id,
                              { headers: headers }
                            );
                            removePicks(index);
                            console.log(response);
                          } catch (e) {
                            console.log('error');
                          }
                        }}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </Avatar>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
          <Typography variant="h3" color="primary" align="center">
            {' '}
            {props.data.message}{' '}
          </Typography>
        )}
      </Paper>
    </div>
  );
}
