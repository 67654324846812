import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { useAuth } from 'context';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { Loading } from '../ProductList';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0)
    }
  },
  content: {
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
    //  margin: 'auto',
    //  maxWidth: 500
  },
 
  textbox: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    padding: theme.spacing(0.75),
    fontWeight: 'bold'
  },
  orangebox: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
    padding: theme.spacing(0.75),
    fontWeight: 'bold'
  },
  greenbox: {
    backgroundColor: '#8bc34a',
    color: theme.palette.white,
    padding: theme.spacing(0.75),
    fontWeight: 'bold'
  },
  listItemText: {
    fontSize: '1em' 
  },
  listItemTextSecondary: {
   paddingTop: theme.spacing(0.75)
  }
}));


const Settings = () => {
  const classes = useStyles();

  const { authTokens } = useAuth();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + authTokens
  };
  const [MyLoading, SetMyLoading] = useState(true);
  const [AllPicksData, setAllPicks] = useState(false);

  useEffect(() => {
    const fetchAllPicks = async () => {
      const result = await axios(process.env.REACT_APP_SETTINGS_ENDPOINT, {
        headers: headers
      });
      if (result.status === 200) {
        setAllPicks(result.data);
        SetMyLoading(false);
      }
    };

    fetchAllPicks();
  }, []);

  let manufacturers = AllPicksData.manufacturers;
  console.log(AllPicksData);
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h4" style={{ paddingLeft: '10px' }}>
          Selling Publisher Settings
        </Typography>
        {MyLoading ? (
          <Loading />
        ) : (
          <Paper>
            <List>
              {manufacturers.map(manufacturer => (
                <ListItem alignItems="flex-start" key={manufacturer.smid}>
                  <ListItemText
                    classes={{ primary: classes.listItemText, secondary: classes.listItemTextSecondary }}
                    primary={
                      'ID: ' + manufacturer.smid + ' - ' + manufacturer.name
                    }
                    secondary={
                      <React.Fragment>
                       
                        <span component="span" className={classes.textbox}>
                          Discount : {manufacturer.discount} %
                        </span>
                        <span component="span"> &nbsp; </span>
                        <span component="span" className={classes.orangebox}>
                          Credit: {manufacturer.creditdays} Days
                        </span>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </div>
    </div>
  );
};
export default Settings;
